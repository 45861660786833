import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the thunk for fetching log data
export const getUserSessionLogsList = createAsyncThunk(
  "logs/getUserSessionLogsList",
  async ({ userId, startDate, endDate, page = 1, perPage = 10 }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          page,
          perPage,
        },
      };

      // Replace with your actual API endpoint
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/sessionLogs/${userId}`,
        config
      );
      return response.data; // Assuming the API returns the data in the response body
    } catch (error) {
      // Handle errors as needed
      throw new Error(
        error.response ? error.response.data.message : error.message
      );
    }
  }
);

export const getUserActivityLogsList = createAsyncThunk(
  "logs/getUserActivityLogsList",
  async ({ userId, startDate, endDate }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { startDate: new Date(startDate), endDate: new Date(endDate) },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/activityLog/${userId}`,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(
        error.response ? error.response.data.message : error.message
      );
    }
  }
);

export const getUserSessionLogProcessedData = createAsyncThunk(
  "logs/getUserSessionLogProcessedData",
  async (
    {
      sort,
      sortColumn,
      q,
      page = 1,
      perPage = 10,
      actions,
      userName,
      userId,
      startDate,
      endDate,
    },
    thunkAPI
  ) => {
    // Fetch data with pagination
    const response = await thunkAPI.dispatch(
      getUserSessionLogsList({ userId, startDate, endDate, page, perPage })
    );

    const allData = response.payload.data;
    const pagination = response.payload.pagination;

    // Filter based on the provided criteria
    const filteredData = allData.filter((user) => {
      return (
        (!q ||
          user?.userId?.firstName.toLowerCase().includes(q.toLowerCase()) ||
          user?.userId?.lastName.toLowerCase().includes(q.toLowerCase())) &&
        (!actions || user?.action === actions) &&
        (!userName || user?.userId?._id === userName)
      );
    });

    // Sort the filtered data
    if (sortColumn && sort) {
      filteredData.sort((a, b) => {
        const aValue = sortColumn.includes(".")
          ? sortColumn.split(".").reduce((obj, key) => obj[key], a)
          : a[sortColumn];
        const bValue = sortColumn.includes(".")
          ? sortColumn.split(".").reduce((obj, key) => obj[key], b)
          : b[sortColumn];
    
        if (sort === "asc") {
          return new Date(aValue) - new Date(bValue); 
        } else {
          return new Date(bValue) - new Date(aValue);
        }
      });
    }
    

    return {
      params: {
        sort,
        sortColumn,
        q,
        page,
        perPage,
      },
      data: filteredData,
      pagination,
    };
  }
);

export const getUserActivityLogsProcessedData = createAsyncThunk(
  "logs/getUserActivityLogsProcessedData",
  async (
    {
      sort,
      sortColumn,
      q,
      page,
      perPage,
      actions,
      userName,
      userId,
      startDate,
      endDate,
    },
    thunkAPI
  ) => {
    // Get all data using the first thunk
    let allData = await thunkAPI.dispatch(
      getUserActivityLogsList({ userId, startDate, endDate })
    );
    allData = allData.payload?.groupedActivity;

    const filteredData = allData.filter((item) => {
      return (
        (!q ||
          item.page.toLowerCase().includes(q.toLowerCase()) ||
          item.date.includes(q)) &&
        (!actions || item.page === actions)
      );
    });

    // Sort the filtered data
    if (sortColumn && sort) {
      filteredData.sort((a, b) => {
        if (sort === "asc") {
          return a[sortColumn] > b[sortColumn] ? 1 : -1;
        } else {
          return a[sortColumn] < b[sortColumn] ? 1 : -1;
        }
      });
    }

    // Paginate the sorted data
    const startIndex = (page - 1) * perPage;
    const paginatedData = allData.slice(startIndex, startIndex + perPage);

    return {
      params: {
        sort,
        sortColumn,
        q,
        page,
        perPage,
      },
      data: paginatedData,
      totalPages: Math.ceil(allData.length / perPage),
    };
  }
);

// Create the slice
export const logsSlice = createSlice({
  name: "userLogs",
  initialState: {
    sessionLogs: [],
    total: 0,
    status: "idle",
    userActivityLogsList: [],
    error: null,
    userDetails: null,
    allActivityListCount: 0,
    allSessionListCount: 0,
    activityGraphData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserSessionLogsList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sessionLogs = action.payload.data;
        state.allSessionListCount = action.payload.pagination.totalLogs;
      })
      .addCase(getUserActivityLogsList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userActivityLogsList = action.payload;
        state.userDetails = action.payload.user;
        state.allActivityListCount = action.payload.groupedActivity?.length;
        state.activityGraphData = action.payload.groupedActivity;
      })
      .addCase(getUserSessionLogProcessedData.fulfilled, (state, action) => {
        state.sessionLogs = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.pagination.totalPages;
      })
      .addCase(getUserActivityLogsProcessedData.fulfilled, (state, action) => {
        state.userActivityLogsList = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
      });
  },
});

export default logsSlice.reducer;
