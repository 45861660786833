import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBlogs = createAsyncThunk(
  "blogs/getBlogs",
  async ({ page, limit, status, q, sort, sortColumn }) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/blogs/getAllBlogsForAdmin`,
        {
          params: { page, limit, status, q, sort, sortColumn },
          ...config,
        }
      );

      return {
        data: response.data.blogs,
        totalPages: response.data.totalPages,
      };
    } catch (error) {
      console.error("Error fetching blogs:", error);
      throw error;
    }
  }
);

export const getBlogById = createAsyncThunk("blogs/getBlogById", async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASEURL}/blog/${id}`
    );
    return response.data.blog;
  } catch (error) {
    console.error("Error fetching blog:", error);
    throw error;
  }
});

export const blogSlice = createSlice({
  name: "blogs",
  initialState: {
    blogs: [],
    total: 1,
    params: {},
    selectedBlog: null,
  },
  reducers: {
    removeBlog: (state, action) => {
      const id = action.payload;

      state.blogs = state.blogs.filter((blog) => blog._id !== id);
    },
    updateBlog: (state, action) => {
      const { id, updatedData } = action.payload;
      state.blogs = state.blogs.map((blog) =>
        blog._id === id ? { ...blog, ...updatedData } : blog
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.blogs = action.payload.data;
        state.total = action.payload.totalPages;
      })
      .addCase(getBlogById.fulfilled, (state, action) => {
        state.selectedBlog = action.payload;
      });
  },
});

export const { removeBlog, updateBlog } = blogSlice.actions;
export default blogSlice.reducer;
